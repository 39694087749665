import { NAMESPACE as USERSELECTION_NAMESPACE, GETTERS as USERSELECTION_GETTERS } from '@/store/modules/userSelection'
import { DayOfWeek, Proposition, School, Service, ServiceKind, ServiceVarietyName } from '@/models'
import gtag from '@/plugins/googleAnalytics/gtagConstants'
import { store } from '@/plugins/vuex'
import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import DaySelectionState from '@/models/types/DaySelectionState'
import moment from 'moment'
import DayCheckboxType from '@/components/InputFields/DaySelector/DayCheckboxType'
import VueCookies from 'vue-cookies'
import Vue from 'vue'

Vue.use(VueCookies)

export enum ContactType {
  Chat,
  Call,
  Mail,
}
// SelectionGuide events

// 3.1 location_search
export function sendLocationSearchAnalyticsEvent (serviceKind: ServiceKind) : void {
  if (isCookieEnabled()) {
    window.dataLayer?.push({
      event: gtag.events.selectionFlow.searchLocations,
      event_name: gtag.events.selectionFlow.searchLocations,
      event_category: gtag.event_category.searchLocations,
      event_action: gtag.event_action.searchLocations,
      event_label: gtag.event_params.registrationType.empty,

      childcare_type: serviceKind === ServiceKind.DayCare ? gtag.event_params.childCareType.KDV : gtag.event_params.childCareType.BSO,
      count_days: getCountedDays(),
      start_of_childcare: moment(store.state.userSelection.formState.startDateOfDayCare).format('MM-YYYY'),
      date_of_birth: moment(store.state.userSelection.formState.dateOfBirth).format('MM-YYYY')
    })
  }
}

// 3.2 school_search
export function sendSchoolSearchAnalyticsEvent () : void {
  if (!store.state.userSelection.formState.isUsingChildbenefitCalculator && isCookieEnabled()) {
    window.dataLayer?.push({
      event: gtag.events.selectionFlow.schoolSearchBso,
      event_name: gtag.events.selectionFlow.schoolSearchBso,
      event_category: gtag.event_category.schoolSearchBso,
      event_action: gtag.event_action.schoolSearchBso,
      event_label: gtag.event_params.registrationType.empty,

      childcare_type: gtag.event_params.childCareType.BSO,
      school_city: store.state.userSelection.formState.selectedSchoolLocality,
      school_name: store.state.userSelection.formState.selectedSchool?.name
    })
  }
}

// 3.3 use_rekentool
export function sendUseCalculatorAnalyticsEvent (serviceVariety : ServiceVarietyName) : void {
  if (isCookieEnabled()) {
    window.dataLayer?.push({
      event: gtag.events.selectionFlow.useChildBenefitCalculator,
      event_name: gtag.events.selectionFlow.useChildBenefitCalculator,
      event_category: gtag.event_category.useChildBenefitCalculator,
      event_action: gtag.event_action.useChildBenefitCalculator,

      count_days: getCountedDays(),
      childcare_type: serviceVariety === ServiceVarietyName.KDV ? gtag.event_params.childCareType.KDV : gtag.event_params.childCareType.BSO
    })
  }
}

// 3.4 choose_location
export function sendChooseLocationAnalyticsEvent (selectedService : Proposition) : void {
  if (isCookieEnabled()) {
    window.dataLayer?.push({
      event: gtag.events.selectionFlow.chooseLocation,
      event_name: gtag.events.selectionFlow.chooseLocation,
      event_category: gtag.event_category.chooseLocation,
      event_action: gtag.event_action.chooseLocation,
      event_label: gtag.event_params.registrationType.registerChild,

      childcare_type: selectedService.kind === ServiceKind.DayCare ? gtag.event_params.childCareType.KDV : gtag.event_params.childCareType.BSO,
      location_adres: selectedService.addressLine1 ?? '',
      location_place: selectedService.locality ?? '',
      mdm_id: selectedService.service.mdmId ?? gtag.event_params.unknownMdmIdValue,
      chosen_days: getSelectedDaysJson()
    })
  }
}

// 3.5 inschrijven start
export function sendReserveRegistrationAnalyticsEvent (selectedService : Proposition) : void {
  if (isCookieEnabled()) {
    window.dataLayer?.push({
      event: gtag.events.selectionFlow.reserveRegistration,
      event_name: gtag.events.selectionFlow.reserveRegistration,
      event_category: gtag.event_category.reserveRegistration,
      event_action: gtag.event_action.reserveRegistration,
      event_label: gtag.event_params.registrationType.registerChild,

      childcare_type: selectedService.kind === ServiceKind.DayCare ? gtag.event_params.childCareType.KDV : gtag.event_params.childCareType.BSO,
      location_adres: selectedService.addressLine1 ?? '',
      location_place: selectedService.locality ?? '',
      mdm_id: selectedService.service.mdmId ?? gtag.event_params.unknownMdmIdValue,
      count_days: getCountedDays(),
      days_chosen: getSelectedDaysString(),
      register_type: isWaitingListOnly() ? gtag.event_params.registerType.waitingList : gtag.event_params.registerType.directRegistration,
      location_available_in_whyplan: true,
      chosen_days: getSelectedDaysJson()
    })
  }
}

// 3.6 book tour
export function sendBookTourAnalyticsEvent (selectedService : Proposition) : void {
  if (isCookieEnabled()) {
    window.dataLayer?.push({
      event: gtag.events.selectionFlow.bookTour,
      event_name: gtag.events.selectionFlow.bookTour,
      event_category: gtag.event_category.bookTour,
      event_action: gtag.event_action.bookTour,
      event_label: gtag.event_params.registrationType.requestTour,

      childcare_type: selectedService.kind === ServiceKind.DayCare ? gtag.event_params.childCareType.KDV : gtag.event_params.childCareType.BSO,
      location_adres: selectedService.addressLine1 ?? '',
      location_place: selectedService.locality ?? '',
      mdm_id: selectedService.service.mdmId ?? gtag.event_params.unknownMdmIdValue,
      count_days: getCountedDays()
    })
  }
}

// 3.7 more information
export function sendMoreInformationAnalyticsEvent (selectedService : Proposition) : void {
  if (isCookieEnabled()) {
    window.dataLayer?.push({
      event: gtag.events.selectionFlow.moreInformation,
      event_name: gtag.events.selectionFlow.moreInformation,
      event_category: gtag.event_category.moreInformation,
      event_action: gtag.event_action.moreInformation,
      event_label: gtag.event_params.moreInformation,

      chilcare_type: selectedService.kind === ServiceKind.DayCare ? gtag.event_params.childCareType.KDV : gtag.event_params.childCareType.BSO,
      location_adres: selectedService.addressLine1 ?? '',
      location_place: selectedService.locality ?? '',
      mdm_id: selectedService.service.mdmId ?? gtag.event_params.unknownMdmIdValue,
      start_of_childcare: moment(store.state.userSelection.formState.startDateOfDayCare).format('MM-YYYY') ?? '',
      birth_date_child: moment(store.state.userSelection.formState.dateOfBirth).format('MM-YYYY') ?? ''
    })
  }
}

// 3.8 discover location pages
export function sendDiscoverOtherLocationsAnalyticsEvent (serviceKind : string) : void {
  if (isCookieEnabled()) {
    window.dataLayer?.push({
      event: gtag.events.selectionFlow.discoverOtherLocations,
      event_name: gtag.events.selectionFlow.discoverOtherLocations,
      event_category: gtag.event_category.discoverOtherLocations,
      event_action: gtag.event_action.discoverOtherLocations,
      event_label: gtag.event_params.registrationType.registerChild,

      childcare_type: serviceKind === ServiceKind.DayCare ? gtag.event_params.childCareType.KDV : gtag.event_params.childCareType.BSO,
      start_of_childcare: moment(store.state.userSelection.formState.startDateOfDayCare).format('MM-YYYY'),
      date_of_birth: moment(store.state.userSelection.formState.dateOfBirth).format('MM-YYYY')
    })
  }
}

// 3.9 location page landing
export function sendLocationPageLandingAnalyticsEvent (selectedService : Proposition) : void {
  if (isCookieEnabled()) {
    window.dataLayer?.push({
      event: gtag.events.selectionFlow.locationPageLanding,
      event_name: gtag.events.selectionFlow.locationPageLanding,
      event_category: gtag.event_category.pageLanding,
      event_action: gtag.event_action.landingOnLocationPage,

      childcare_type: selectedService.kind === ServiceKind.DayCare ? gtag.event_params.childCareType.KDV : gtag.event_params.childCareType.BSO,
      location_adres: selectedService.addressLine1 ?? '',
      location_locality: selectedService.locality ?? '',
      mdm_id: selectedService.service.mdmId ?? gtag.event_params.unknownMdmIdValue
    })
  }
}

// 3.10 registration
export function sendSubscriptionChosenAnalyticsEvent () : void {
  if (isCookieEnabled()) {
    window.dataLayer?.push({
      event: gtag.events.selectionFlow.subscriptionChosen,
      event_name: gtag.event_category.subscriptionChosen,
      event_category: gtag.event_category.subscriptionChosen,
      event_action: gtag.event_action.subscriptionChosen,
      event_label: gtag.event_params.registrationType.registerChild,

      childcare_type: store.state.userSelection.formState.selectedServiceKind === ServiceKind.DayCare ? gtag.event_params.childCareType.KDV : gtag.event_params.childCareType.BSO,
      package_chosen: store.state.userSelection.formState.selectedSubscription?.subscription.name,
      mdm_id: store.state.userSelection.formState.selectedService?.mdmId ?? gtag.event_params.unknownMdmIdValue,
      start_of_childcare: moment(store.state.userSelection.formState.startDateOfDayCare).format('MM-YYYY'),
      chosen_days: getSelectedDaysJson()
    })
  }
}

// 3.11 registeration
export function sendPersonalDataAnalyticsEvent () : void {
  if (isCookieEnabled()) {
    window.dataLayer?.push({
      event: gtag.events.selectionFlow.personalData,
      event_name: gtag.event_category.personalData,
      event_category: gtag.event_category.personalData,
      event_action: gtag.event_action.personalData,
      event_label: gtag.event_params.registrationType.registerChild,

      childcare_type: store.state.userSelection.formState.selectedServiceKind === ServiceKind.DayCare ? gtag.event_params.childCareType.KDV : gtag.event_params.childCareType.BSO,
      mdm_id: store.state.userSelection.formState.selectedService?.mdmId ?? gtag.event_params.unknownMdmIdValue,
      date_of_birth: moment(store.state.userSelection.formState.dateOfBirth).format('MM-YYYY')
    })
  }
}

// 3.12 registeration
export function sendConfirmRegistrationAnalyticsEvent () : void {
  if (isCookieEnabled()) {
    window.dataLayer?.push({
      event: gtag.events.selectionFlow.confirmRegistration,
      event_name: gtag.event_category.confirmRegistration,
      event_category: gtag.event_category.confirmRegistration,
      event_action: gtag.event_action.confirmRegistration,
      event_label: gtag.event_params.registrationType.registerChild,

      childcare_type: store.state.userSelection.formState.selectedServiceKind === ServiceKind.DayCare ? gtag.event_params.childCareType.KDV : gtag.event_params.childCareType.BSO,
      mdm_id: store.state.userSelection.formState.selectedService?.mdmId ?? gtag.event_params.unknownMdmIdValue,
      chosen_days: getSelectedDaysJson(),
      date_of_birth: moment(store.state.userSelection.formState.dateOfBirth).format('MM-YYYY'),
      start_of_childcare: moment(store.state.userSelection.formState.startDateOfDayCare).format('MM-YYYY'),
      location_address: store.state.userSelection.formState.selectedService?.location?.addressLine1,
      location_place: store.state.userSelection.formState.selectedService?.location?.locality
    })
  }
}

// 3.13 verwijderd voor KF-6312

// 3.14 waiting_list_push
export function sendWaitingListSelectedAnalyticsEvent (accepted: boolean) : void {
  if (isCookieEnabled()) {
    window.dataLayer?.push({
      event: gtag.events.selectionFlow.waitingListSelected,
      event_name: gtag.event_category.waitingListSelected,
      event_category: gtag.event_category.waitingListSelected,
      event_action: gtag.event_action.waitingListSelected,
      event_label: gtag.event_params.registrationType.registerChild,

      choice: accepted ? 'Ja graag' : 'Terug',
      childcare_type: store.state.userSelection.formState.selectedServiceKind === ServiceKind.DayCare ? gtag.event_params.childCareType.KDV : gtag.event_params.childCareType.BSO,
      mdm_id: store.state.userSelection.formState.selectedService?.mdmId ?? gtag.event_params.unknownMdmIdValue,
      register_type: isWaitingListOnly() ? gtag.event_params.registerType.waitingList : gtag.event_params.registerType.directRegistration,
      start_of_childcare: moment(store.state.userSelection.formState.startDateOfDayCare).format('MM-YYYY'),
      location_adres: store.state.userSelection.formState.selectedService?.location?.addressLine1 ?? '',
      location_place: store.state.userSelection.formState.selectedService?.location?.locality ?? '',
      date_of_birth: moment(store.state.userSelection.formState.dateOfBirth).format('MM-YYYY')
    })
  }
}

// 3.15 push message maximum months
export function sendContactClickedEvent (contactType: ContactType) : void {
  if (isCookieEnabled()) {
    let contactTypeString = ''
    switch (contactType) {
    case ContactType.Chat:
      contactTypeString = gtag.events.selectionFlow.chat
      break
    case ContactType.Call:
      contactTypeString = gtag.events.selectionFlow.call
      break
    case ContactType.Mail:
      contactTypeString = gtag.events.selectionFlow.mail
      break
    }
    window.dataLayer?.push({
      event: gtag.events.selectionFlow.contactClicked,
      event_name: contactTypeString,
      event_category: gtag.event_category.contact,
      event_action: gtag.event_action.contactClicked
    })
  }
}

// 3.16 mdm id on service detail page visit
export function sendMdmIdEvent (selectedService : Proposition) : void {
  if (isCookieEnabled()) {
    window.dataLayer?.push({
      event: gtag.events.selectionFlow.mdm,

      mdm: selectedService.service.mdmId ?? gtag.event_params.unknownMdmIdValue,
      childcare_type: selectedService.serviceVarieties[0].name === ServiceVarietyName.KDV ? gtag.event_params.childCareType.KDV : gtag.event_params.childCareType.BSO
    })
  }
}

// 3.17 custom registration
export function sendCustomSubscriptionChosenAnalyticsEvent () : void {
  if (isCookieEnabled()) {
    window.dataLayer?.push({
      event: gtag.events.selectionFlow.subscriptionChosen,
      event_name: gtag.event_category.customSubscriptionChosen,

      childcare_type: store.state.userSelection.formState.selectedServiceKind === ServiceKind.DayCare ? gtag.event_params.childCareType.KDV : gtag.event_params.childCareType.BSO,
      mdm_id: store.state.userSelection.formState.selectedService?.mdmId ?? gtag.event_params.unknownMdmIdValue,
      start_of_childcare: moment(store.state.userSelection.formState.startDateOfDayCare).format('MM-YYYY')
    })
  }
}

// 3.18 external booking
export function sendBookExternalAnalyticsEvent (currentUrl: string, redirectUrl: string, selectedService : Proposition) : void {
  if (isCookieEnabled()) {
    window.dataLayer?.push({
      event: gtag.events.selectionFlow.externalBooking,
      event_name: gtag.event_category.externalBooking,
      event_category: gtag.event_category.registrationStart,
      event_action: gtag.event_action.chooseDateAndTime,
      childcare_type: selectedService.serviceVarieties[0].name === ServiceVarietyName.KDV ? gtag.event_params.childCareType.KDV : gtag.event_params.childCareType.BSO,
      location_adres: selectedService.addressLine1 ?? '',
      location_place: selectedService.locality ?? '',
      mdm_id: selectedService.service.mdmId ?? gtag.event_params.unknownMdmIdValue,
      previousURL: currentUrl,
      goingURL: redirectUrl
    })
  }
}

// Child benefit calculator

// 2.2.3 calculation completed
export function sendCompletedCalculationAnalyticsEvent (service: Service, uniqueDays: DayOfWeek[], school?: School | undefined) : void {
  if (isCookieEnabled()) {
    window.dataLayer?.push({
      event: gtag.events.calculation.calculation,
      event_name: gtag.events.calculation.completedCalculation,
      event_action: gtag.events.calculation.completedCalculation,
      event_category: gtag.events.calculation.calculation,
      event_label: gtag.events.calculation.calculation,

      childcare_type: service.kind,
      choose_city_school: school?.locality ?? undefined,
      school_name: school?.name ?? undefined,
      location_adres: service.location?.addressLine1,
      location_place: service.location?.locality,
      count_days: uniqueDays.length
    })
  }
}

function isCookieEnabled () : boolean {
  const cookiesConsent : string = Vue.$cookies.get('CookieConsent')
  if (cookiesConsent?.toLowerCase().includes('statistics:true')) {
    return true
  }
  return false
}

function isWaitingListOnly () : boolean {
  const selectedDays = getSelectedDays() as Record<ServiceVarietyName, DayCheckboxState[]>
  return Object.values(selectedDays).flatMap(x => x).filter(x => x.isChecked).every(x => x.type === DayCheckboxType.Wait)
}

function getCountedDays () : number {
  const selectedDays = getSelectedDays()
  let count = 0
  for (const key in Object.keys(selectedDays)) {
    count = Math.max(count, Object.values(selectedDays)[key].filter(x => x.isChecked).length)
  }
  return count
}

function getSelectedDaysString () : string {
  const selectedDays = getSelectedDays()
  const days = [
    selectedDays.KDV?.filter(x => x.isChecked).map(x => x.day.toString()),
    selectedDays.VSO?.filter(x => x.isChecked).map(x => x.day.toString()),
    selectedDays.NSO?.filter(x => x.isChecked).map(x => x.day.toString())
  ]
  const uniqueDays = days.filter((value, index) => days.indexOf(value) === index).flat(1)
  return uniqueDays.join(', ')
}

enum Availability {
  Wait = 'waitinglist',
  Check = 'available',
  Closed = 'closed'
}

function getSelectedDaysJson () : object {
  const getDayCheckboxState : (withAvailability: boolean, withOpeningHours: boolean, offerId?: string, uncheckClosedDay?: boolean) => Record<ServiceVarietyName, DayCheckboxState[]> =
    store.getters[`${USERSELECTION_NAMESPACE}/${USERSELECTION_GETTERS.getDayCheckboxState}`]
  const selectedDays = getDayCheckboxState(true, true, undefined, false)

  const selectedDaysForKdv = selectedDays?.KDV.filter(x => x.isChecked)
  const selectedDaysForVso = selectedDays?.VSO.filter(x => x.isChecked)
  const selectedDaysForNso = selectedDays?.NSO.filter(x => x.isChecked)

  return {
    ...(selectedDaysForKdv?.length > 0 && {
      kdv: selectedDaysForKdv.map(x => {
        return { [x.day.toString().toLowerCase()]: Availability[x.type] }
      })
    }),
    ...(selectedDaysForVso?.length > 0 && {
      vso: selectedDaysForVso.map(x => {
        return { [x.day.toString().toLowerCase()]: Availability[x.type] }
      })
    }),
    ...(selectedDaysForNso?.length > 0 && {
      nso: selectedDaysForNso.map(x => {
        return { [x.day.toString().toLowerCase()]: Availability[x.type] }
      })
    })
  }
}

function getSelectedDays () : Partial<Record<ServiceVarietyName, DaySelectionState[]>> | Record<ServiceVarietyName, DayCheckboxState[]> {
  if (store.state.userSelection.formState.selectedService?.id) {
    const getDayCheckboxState : (withAvailability: boolean) => Record<ServiceVarietyName, DayCheckboxState[]> =
    store.getters[`${USERSELECTION_NAMESPACE}/${USERSELECTION_GETTERS.getDayCheckboxState}`]
    return getDayCheckboxState(true)
  } else {
    return store.state.userSelection.formState.daysPerServiceVariety
  }
}
