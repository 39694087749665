import LocaleMessages from './LocaleMessages'

const nl: LocaleMessages = {
  pageTitles: {
    selectionGuide: 'Vind jouw opvang | Partou',
    schoolSelectionPage: 'Vind jouw opvang | Partou',
    locations: 'Vind jouw {serviceVarietyName} in {locality} | Partou',
    locationDetail: '{serviceVarietyName} {name} | Partou',
    orderOverview: 'Jouw opvangpakket | Partou',
    orderOverviewSelectProduct: 'Jouw opvangpakket | Partou',
    orderOverviewAcceptingWaitinglist: 'Jouw wachtlijst inschrijving | Partou',
    orderOverviewSelectProductAcceptingWaitinglist: 'Jouw wachtlijst inschrijving | Partou',
    orderContactInfo: 'Jouw gegevens | Partou',
    orderContactInfoCheck: 'Jouw gegevens | Partou',
    bookingStatus: 'Jouw boeking | Partou',
    signContract: 'Uitbreidingsovereenkomst | Partou',
    emailConfirm: 'Bevestig jouw e-mailadres | Partou',
    waitingList: 'Wachtlijst | Partou',
    sharedPage: 'Gedeelde link | Partou',
    notFound: '404 | Partou'
  },
  pageUrls: {
    selectionGuide: '/',
    selectionGuideWithPostalCode: '/postcode/:postalCode',
    schoolSelectionPage: '/scholen',
    locations: 'zoeken',
    locationDetail: '/:serviceSlug',
    orderOverview: '/:serviceSlug/opvangpakketten',
    orderContactInfo: '/:serviceSlug/jouw-gegevens',
    orderContactInfoCheck: '/:serviceSlug/jouw-overzicht',
    bookingStatus: '/boeking/:bookingHash',
    signContract: '/contract/:bookingHash',
    emailConfirm: '/boeking/:bookingHash/confirm/:confirmationHash',
    waitingList: '/wachtlijst/:bookingHash',
    waitingListChildren: {
      cancel: 'annuleren',
      dayOverview: 'aanbod',
      dayOverviewAccept: 'aanbod/accepteren',
      dayOverviewDecline: 'aanbod/weigeren',
      cancelSuccess: 'success',
      reactionReceived: 'ontvangen',
      booked: 'voltooid',
      offerExpired: 'verlopen',
      unavailable: 'ongeldig',
      extend: 'behouden',
      requiresPlanner: 'planner'
    },
    shareWithData: '/delen/:hash/:encryptionKey',
    serviceKindDayCareSuffix: 'kinderdagverblijf',
    serviceKindSchoolCareSuffix: 'bso'
  },
  pageDescriptions: {
    selectionGuide: 'We laten je graag zien hoe wij jouw kindje kunnen helpen opgroeien. Vind een kinderopvang bij jou in de buurt en kom eens een kijkje nemen!',
    locations: '{serviceVarietyName} Partou in {locality}: plezier met vriendjes ✓ Speciale oefeningen voor baby’s ✓ Liefdevol ✓ Foto’s via onze app  ✓ Vind een kinderdagverblijf'
  },
  externalWebsiteTranslations: {
    caretypes: {
      BSO: 'bso',
      KDV: 'kinderdagverblijf'
    }
  },
  sharingMenu: {
    shareButtonName: 'Link delen',
    sharingTitle: 'Wat wil je delen?',
    sharingWithoutDataBtnText: 'Opvanglocatie',
    sharingWithDataBtnText: 'Opvanglocatie & Ingevulde gegevens'
  },
  errorPopUp: {
    title: 'Sorry! Er is iets fout gegaan',
    text: 'Probeer het nog een keer of neem contact op om persoonlijk geholpen te worden.',
    back: 'Terug naar het begin'
  },
  bsoTransitionPopup: {
    title: 'Op zoek naar buitenschoolse opvang?',
    text: '<p>Je kind wordt binnenkort 4 jaar en kan dan naar onze buitenschoolse opvang (bso).<br/><br/>Wil je een buitenschoolse opvang zoeken? In dat geval passen we je startdatum aan naar <b>{newStartDate}</b>.</p>',
    confirm: 'Ja graag',
    decline: 'Nee ik zoek een kinderdagverblijf',
    help: 'Wil je starten op een bso nog vóórdat je kind 4 jaar wordt, neem dan contact op met onze afdeling Planning.'
  },
  partouSocials: {
    questionsOrHelp: 'Vragen of hulp nodig?',
    callButtonName: 'Neem contact op via de telefoon',
    chatButtonName: 'Neem contact op via chat',
    emailButtonName: 'Neem contact op via e-mail',
    phoneText: 'We zijn telefonisch bereikbaar van maandag tot en met vrijdag van 8.30 tot 17.00.',
    chatText: 'Chat met ons. Eenvoudig op jouw manier!',
    mailText: 'Neem contact met ons op zodat we samen kunnen kijken wat de mogelijkheden zijn.',
    copied: 'Gekopieerd!'
  },
  form: {
    required: 'Veld is verplicht',
    invalidEmail: 'Ongeldig e-mailadres',
    invalidPhoneNumber: 'Ongeldig telefoonnummer',
    invalidInitials: 'Ongeldige voorletter(s)',
    invalidName: 'Ongeldige naam',
    invalidBsn: 'Ongeldige BSN',
    sameBsn: 'Burgerservicenummer mag niet hetzelfde zijn als {0}',
    sameBsnMultiple: 'Burgerservicenummer mag niet hetzelfde zijn als {0} en {1}',
    invalidDate: 'Ongeldige datum',
    dateBeforeMinDate: 'Kies een datum vanaf {date}',
    dateAfterMaxDate: 'Kies een datum voor {date}',
    minimalAgeRequirement: 'Je moet minimaal {age} jaar oud zijn om je kind in te kunnen schrijven',
    invalidPostalCode: 'Ongeldige postcode',
    invalidHouseNumber: 'Ongeldig huisnummer',
    invalidHouseNumberAddition: 'Ongeldig huisnummer toevoeging',
    unknownPostalCodeHouseNumberCombination: 'Postcode / huisnummer combinatie is onbekend',
    noData: 'Er is geen data beschikbaar'
  },
  BSO: 'Bso',
  VSO: 'Voorschoolse opvang',
  NSO: 'Naschoolse opvang',
  KDV: 'Kinderdagverblijf',
  daycare: 'opvang',
  openingTimes: {
    unknown: 'Onbekend',
    closed: 'Gesloten',
    schoolTime: 'Schooltijd'
  },
  days: {
    monday: 'Maandag',
    tuesday: 'Dinsdag',
    wednesday: 'Woensdag',
    thursday: 'Donderdag',
    friday: 'Vrijdag',
    saturday: 'Zaterdag',
    sunday: 'Zondag'
  },
  serviceVarieties: {
    KDV: 'Kinderdagverblijf',
    NSO: 'Naschoolse',
    VSO: 'Voorschoolse',
    Daycare: 'opvang'
  },
  daySelector: {
    wait: 'Wachtlijst',
    closed: 'Gesloten',
    placeAvailable: 'Direct Plek!',
    notAvailable: 'niet beschikbaar',
    startsFrom: 'Start op',
    errorMsgMinimumSelected: 'Selecteer minimaal één dag',
    chooseYourDaysExplanation: 'Bij \'Direct plek\' kunnen we je kind meteen verwelkomen. Bij de andere opties is er op dit moment niet direct beschikbaarheid. Maar geen zorgen! Onze planners gaan samen met jou kijken naar de mogelijkheden.',
    ariaLabel: {
      monday: 'Maandag',
      tuesday: 'Dinsdag',
      wednesday: 'Woensdag',
      thursday: 'Donderdag',
      friday: 'Vrijdag',
      checked: 'geselecteerd',
      unchecked: 'niet geselecteerd',
      waitinglist: 'wachtlijst'
    }
  },
  notification: {
    cancel: 'Terug',
    close: 'Sluiten',
    ok: 'Oke'
  },
  autocomplete: {
    noData: 'Er is geen data beschikbaar',
    noSearchResults: 'Geen resultaten voor {search} '
  },
  locationSearch: {
    localityLabel: 'In welke plaats?',
    localityPlaceholder: 'Plaatsnaam',
    offerLabel: 'Bij welke Partou?',
    offerPlaceholder: 'locatie'
  },
  childBenefitCalculator: {
    upperTitle: 'Voor deze locatie',
    title: 'Kostenindicatie',
    altTitle: 'Voor deze locatie',
    introText: 'Bereken met deze tool makkelijk en snel de verwachte netto opvangkosten per uur voor `alle` opvanglocaties.',
    introTextDisclaimer: 'De prijsindicatie is gebaseerd op direct beschikbare dagen',
    form: {
      cumulativeIncome: {
        text: 'Wat is het verzamelinkomen?',
        placeholder: 'Verzamelinkomen in euro',
        suffix: 'euro'
      },
      workingHours: {
        text: 'Werkuren minst werkende ouder?',
        suffix: 'uur'
      },
      currentAndFutureServicePricings: {
        description: 'Berekenen met de prijzen vanaf',
        text: 'Prijzen vanaf'
      }
    },
    invoiceAmount: 'Factuurbedrag',
    amountChosenHours: 'Gekozen aantal uren',
    benefitAmount: 'Kinderopvangtoeslag',
    ownContribution: 'Eigen bijdrage',
    calculatePrice: 'Kosten opvang',
    calculateNetPrice: 'Netto uurprijs berekenen',
    hour: 'uur',
    perHour: 'per uur',
    perMonth: 'per maand',
    hourPerMonthShort: 'uur p/m',
    maximumHours: 'Maximum opvanguren',
    declarableHours: 'Gekozen opvanguren',
    remainingHours: 'Ruimte',
    table: {
      heading: 'Dit is jouw...'
    },
    info: {
      general: {
        heading: 'Kostenindicatie informatie',
        text: 'De overheid betaalt een percentage van de kosten van kinderopvang. De hoogte van deze toeslag (kinderopvangtoeslag) hangt af van jouw inkomen. Hoe lager je inkomen, hoe hoger de tegemoetkoming. De berekening wordt uitgevoerd volgens de toeslagenberekening voor {0}. Meer informatie is te vinden op: <br/><br/><a target="_blank" href="https://www.belastingdienst.nl/wps/wcm/connect/nl/toeslagen/content/hulpmiddel-proefberekening-toeslagen">https://www.belastingdienst.nl/wps/wcm/connect/nl/toeslagen/content/hulpmiddel-proefberekening-toeslagen</a>',
        disclaimer: {
          heading: 'Disclaimer',
          text: 'Deze kostenindicatie is met de grootst mogelijke zorg samengesteld. Partou kan echter niet garanderen dat de informatie en rekenresultaten vrij zijn van fouten of onvolkomenheden. Partou is niet aansprakelijk voor enige schade die is of kan ontstaan door gebruik van deze gegevens. Gebruik van de informatie en gegevens op deze kostenindicatie is geheel voor eigen risico. De informatie op deze kostenindicatie is uitsluitend bedoeld als algemene informatie en voor algemeen gebruik en uitdrukkelijk niet bedoeld als financieel, fiscaal, juridisch of andersoortig professioneel advies. Er kunnen geen rechten aan de informatie op deze kostenindicatie worden ontleend.'
        }
      },
      cumulativeIncome: {
        heading: 'Verzamelinkomen',
        text: 'De belastingdienst berekent de hoogte van je kinderopvangtoeslag op basis van je verzamelinkomen (ook wel toetsingsinkomen genoemd). Je verzamelinkomen is het gezamenlijke bruto jaarinkomen van jou en je eventuele partner, minus aftrekposten (bijvoorbeeld hypotheekrente). Op de site van de Belastingdienst staat een rekenhulp om je verzamelinkomen te berekenen:<br/><br/><a target="_blank" href="https://www.belastingdienst.nl/wps/wcm/connect/nl/toeslagen/content/wat-is-mijn-toetsingsinkomen">https://www.belastingdienst.nl/wps/wcm/connect/nl/toeslagen/content/wat-is-mijn-toetsingsinkomen</a>'
      },
      workingHours: {
        heading: 'Minst werkende ouder',
        text: 'Het aantal uren waarover je recht hebt op kinderopvangtoeslag is afhankelijk van het aantal uren dat de minst werkende partner werkt. Voor dagopvang en buitenschoolse opvang heb je recht op 140% van de uren van de minst werkende partner. Werk jij bijvoorbeeld 40 uur per week en je partner 24, vul dan 24 in. Als je wisselende werkuren hebt, vul je het gemiddelde aantal uren in.'
      },
      benefitAmount: {
        heading: 'Kinderopvangtoeslag',
        text: 'De overheid betaalt een percentage van de kosten van kinderopvang. De hoogte van jouw kinderopvangtoeslag hangt af van het verzamelinkomen. Hoe lager het inkomen, hoe hoger de tegemoetkoming.<br/><br/>O.b.v. het door jou ingevulde verzamelinkomen van <strong>{0}</strong> heb je recht op een tegemoetkoming van <strong>{1}%</strong> van het maximale uurtarief dat de overheid vergoedt ({3}: <strong>{2} p/uur</strong>).<br/><br/>Jouw kinderopvangtoeslag bedraagt <strong>{4}</strong>% van <strong>{5}</strong> = <strong>{6}</strong> per uur'
      },
      maximumHours: {
        heading: 'Kinderopvangtoeslag voor een maximum aantal uren',
        text: 'Het aantal opvanguren waarover je recht hebt op kinderopvangtoeslag is afhankelijk van het aantal uren dat de minst werkende partner werkt. <br/><br/>Je  hebt recht op <strong>{0}%</strong> van de uren van de minst werkende partner <br/><br/>O.b.v. het door jou ingevulde aantal van <strong>{2} uur</strong> heb je recht op het volgende aantal uren kinderopvangtoeslag:<br/><br/>{3} &times; {4} &times; 52 weken ÷ 12 maanden ≈ <strong>{5}</strong> uur per maand'
      }
    },
    attention: {
      heading: 'Let op!',
      moreText: 'Je hebt nu meer uren geselecteerd dan het maximum aantal uur (230 uur) waarvoor je recht hebt op kinderopvangtoeslag (KOT). Voor deze meeruren betaal je 100% van de kosten.'
    },
    moreInformation: 'meer informatie',
    lessInformation: 'minder informatie',
    saveButton: {
      textContinue: 'Verder',
      textShowPrices: 'Toon netto tarieven'
    },
    disclaimer: 'Aan dit overzicht kunnen geen rechten worden ontleend. Alle gepubliceerde bedragen zijn onder voorbehoud van typefouten.',
    inputForm: {
      infoSectionTitle: 'Jouw gegevens',
      careSectionTitle: 'Jouw opvangsoort',
      schoolSectionTitle: 'Jouw school',
      locationSectionTitle: 'Jouw opvang',
      locationSectionText: 'Kies jouw Partou',
      daySelectionText: 'Kies je opvangdagen',
      calculateCosts: 'Bereken je kosten'
    },
    noCalculationPossibleHintTitle: 'Neem even contact met ons op!',
    noCalculationPossibleHintText: 'Voor deze locatie kan er momenteel geen prijsindicatie gegeven worden. Klik op één van de contactbuttons om samen de mogelijkheden te bespreken.',
    noCalculationPossibleSchoolHintText: 'Door de geselecteerde school kan er momenteel geen prijsindicatie gegeven worden voor onze locaties. Klik op één van de contactbuttons om samen de mogelijkheden te bespreken.',
    editInfo: 'Gegevens aanpassen'
  },
  serviceOverviewPage: {
    backButtonName: 'Terug',
    resultLength: '{length} resultaten',
    daySelectorInfo: 'Op welke dagen wil je opvang?',
    showMore: 'Toon meer resultaten',
    serviceCard: {
      moreInformation: 'Ontdek de locatie',
      moreInformationAriaLabel: 'Meer informatie over {location}',
      availabilityIndicator: {
        available: 'Direct Plek!',
        partlyAvailable: 'Deels plek!',
        unavailable: 'Schrijf je in'
      }
    },
    selectionPanel: {
      showPanel: 'Voorkeursdagen wijzigen',
      showPanelAriaLabel: 'Open paneel voorkeursdagen wijzigen',
      closePanelAriaLabel: 'Sluit paneel'
    }
  },
  orderOverviewPage: {
    backButtonName: 'Terug',
    selection: {
      supertitle: 'Kies',
      title: 'Jouw opvangpakket',
      expansionTitle: 'Uitbreiding van jouw opvangpakket',
      location: 'Locatie',
      startDate: 'Startdatum',
      noSelection: 'geen',
      availableDaysMessage: 'Hieronder zie je welke dag(en) beschikbaar gaan komen en vanaf wanneer.',
      chooseDays: 'Kies welke dag(en) je graag wilt afnemen.',
      expansionChooseDays: 'Kies welke dag(en) je wilt toevoegen aan je huidige opvangpakket.',
      stillUnavailableDisclaimer: 'Voor de dagen die nu nog niet beschikbaar zijn, blijven we zoeken naar een oplossing. En als dat gelukt is, laten we je dat direct weten!',
      subscription: {
        cheapestChoice: 'Voordeligste keuze',
        careDuring: 'Opvang tijdens',
        subText: 'weken opvang per jaar',
        perHour: 'per uur',
        featureDisclaimer: '* Afhankelijk van beschikbaarheid',
        groupDisclaimer: '* We proberen jouw kind zo veel mogelijk op een vaste groep te plaatsen. Mocht je kindje toch op een tweede groep worden geplaatst, dan zullen we hierover contact met je opnemen zodat je altijd vooraf toestemming kan geven.'
      },
      customSubscription: {
        title: 'Niet gevonden wat je zocht?',
        callToAction: 'Zijn onze handige pakketten toch niet passend voor jouw situatie? Neem dan contact met ons op, dan kijken we samen wat de mogelijkheden zijn.',
        formCTA: 'In het opmerkingenveld van het formulier dat hierna volgt, kun je je wensen aangeven.',
        buttonText: 'Ander aanbod'
      }
    },
    productSelection: {
      nsoTimeQuestion: 'Hoe laat wil je je kind ophalen van ',
      nso: 'naschoolse opvang?',
      vsoTimeQuestion: 'Hoe laat wil je je kind brengen voor ',
      vso: 'voorschoolse opvang?',
      kdvTimeQuestion: 'Hoe laat wil je je kind komen brengen en ophalen?',
      hourlyRateKDV: 'Jouw gekozen uurprijs ',
      hourlyRateNSO: 'Jouw gekozen uurprijs voor naschoolse opvang ',
      hourlyRateVSO: 'Jouw gekozen uurprijs voor voorschoolse opvang ',
      perHour: 'per uur',
      cheapestChoice: 'Voordeligste keuze',
      careDuring: 'Opvang tijdens',
      subText: 'weken opvang per jaar',
      featureDisclaimer: '* Afhankelijk van beschikbaarheid',
      groupDisclaimer: '* We proberen jouw kind zo veel mogelijk op een vaste groep te plaatsen. Mocht je kindje toch op een tweede groep worden geplaatst, dan zullen we hierover contact met je opnemen zodat je altijd vooraf toestemming kan geven.',
      AllWeeks: 'Alle weken',
      SchoolWeeks: 'Schoolweken',
      textualAdditionNSO: 'Notitie naschoolse opvang:',
      textualAdditionVSO: 'Notitie voorschoolse opvang:'
    },
    linkToPartouOffers: {
      title: 'Niet gevonden wat je zocht?',
      callToAction: 'Zijn onze handige pakketten toch niet passend voor jouw situatie? Neem dan contact met ons op, dan kijken we samen wat de mogelijkheden zijn.',
      formCTA: 'In het opmerkingenveld van het formulier dat hierna volgt, kun je je wensen aangeven.',
      buttonText: 'Ander aanbod'
    },
    noDaysOrSchoolSelected: 'Selecteer een dag en een school om de abonnementen te kunnen inzien',
    offerNotAvailable: 'Voor deze locatie is het nog niet mogelijk om je online in te schrijven.<br/><br/>Neem contact met ons op via één van de contactbuttons om persoonlijk geholpen te worden.',
    priceSummary: {
      headerText: 'De prijsindicatie is gebaseerd op direct beschikbare dagen',
      invoiceAmount: 'Factuurbedrag',
      perMonth: 'per maand',
      allowance: 'Kinderopvangtoeslag',
      contribution: 'Eigen bijdrage',
      indicationPerMonth: 'indicatie per maand',
      orderButton: 'Verder',
      calculateCosts: 'Kosten opvang berekenen',
      calculaterButtonName: 'Kosten berekenen'
    },
    acceptWaitingListActions: {
      orderButton: 'Geselecteerde dagen reserveren',
      declineButton: 'Nee, nu (nog) even niet',
      declineDialog: {
        heading: 'Wachtlijst plekken afwijzen',
        text: 'Weet je zeker dat je je wachtlijst aanbod wilt afwijzen? Je blijft wel op de wachtlijst staan.',
        confirmText: 'Ja, graag'
      }
    },
    declineReasonPicker: {
      placeholder: 'Selecteer je reden',
      commentPlaceholder: 'Licht hier je reden toe (optioneel)'
    }
  },
  orderContactInfoFormPage: {
    supertitle: 'Jouw',
    title: 'Gegevens',
    subtitle: 'Om jouw overeenkomst op te stellen, hebben we een aantal gegevens van je nodig.',
    backButtonName: 'Terug',
    careTaker: 'Verzorger',
    child: 'Kind',
    primaryCareTaker: 'Verzorger 1',
    secondaryCareTaker: 'Verzorger 2',
    optional: 'Optioneel:',
    addCareTaker: 'Extra verzorger toevoegen',
    removeCareTaker: '{0} verwijderen',
    thisCareTaker: 'Deze verzorger',
    defaultChildName: 'Baby',
    form: {
      personalInfo: 'Persoonsgegevens',
      editPersonalInfo: 'Pas persoonsgegevens aan',
      initials: 'Voorletter(s)',
      firstName: 'Roepnaam',
      middleName: 'Tussenvoegsel',
      lastName: 'Achternaam',
      gender: 'Geslacht',
      genders: {
        female: 'Meisje',
        male: 'Jongen',
        other: 'Overige'
      },
      bsn: 'Burgerservicenummer (BSN)',
      bsnHyphenated: 'Burgerservice-nummer (BSN)',
      contactInfo: 'Contactgegevens',
      emailAddress: 'E-mailadres',
      phoneNumber: 'Telefoonnummer',
      addressInfo: 'Adresgegevens',
      postalCode: 'Postcode',
      houseNumber: 'Huisnummer',
      houseNumberAddition: 'Toevoeging',
      street: 'Straatnaam',
      locality: 'Woonplaats',
      childInfoSuper: 'De minimaal benodigde',
      childInfo: 'Kindgegevens',
      editChildInfo: 'Pas kindgegevens aan',
      dateOfBirth: 'Geboortedatum',
      informationIsCorrect: 'Bovenstaande informatie klopt',
      agreedToPrivacyPolicy: 'Ik ga akkoord met de vastlegging van mijn gegevens door Partou. <a href="{privacyLink}" target="_blank">Partou respecteert jouw privacy.</a>',
      agreedToTermsText: 'Ik ga akkoord met de <a href="{termsLink}" target="_blank">algemene en aanvullende voorwaarden</a>',
      agreedToExpansionText: 'Ik ga akkoord met het wijzigen van mijn opvangovereenkomst, met behoud van de huidige bepalingen, behalve het herroepingsrecht.'
    },
    notification: {
      error: 'Er is iets misgegaan. Probeer het opnieuw.',
      errorSeatAlreadyTaken: 'Helaas is de plek niet meer beschikbaar. Je wordt teruggestuurd naar de locatielijst'
    },
    perWeek: 'per week',
    perMonth: 'per maand',
    monthlyAmount: 'Maandbedrag',
    days: 'dag(en)',
    orderButton: 'Verder'
  },
  orderContactInfoCheckPage: {
    supertitle: 'Controleer jouw',
    title: 'Gegevens',
    careTakerOne: 'Verzorger 1',
    careTakerTwo: 'Verzorger 2',
    childInfo: 'Kind gegevens',
    orderButton: 'Bevestigen',
    bookLoadingText: 'Bevestigen kan even duren (tot wel 2 minuten)!'
  },
  selectionGuidePage: {
    welcomeTitle: '<b style="">Vind een Partou</b><span class="cera-pro-regular"> bij jou in de buurt</span>',
    welcomeText: 'Vriendjes maken, spelen, meedoen aan een leuke activiteit en samen de wereld ontdekken. Dat kan bij Partou. <br/><br/>Ontdek de Partou-locaties bij jou in de buurt! ',
    locationsButton: 'Vind jouw Partou',
    selectionGuideForm: {
      postalCodeInfo: 'Wat is je postcode?',
      expectedDateOfBirthInfo: 'Wat is de (verwachte) geboortedatum van je kind?',
      startDateOfDayCareInfo: 'Wanneer wil je starten met de opvang?',
      daySelectorInfo: 'Op welke dagen wil je opvang?',
      postalcodeTooltip: 'Wil je liever opvang dicht bij je werk? Vul dan de postcode van je werk in.',
      daySelector: {
        errorMsgMinimumSelected: 'Vul minimaal één dag in'
      },
      postalCodeInput: {
        postalCodePlaceholder: '1234 AB',
        error: {
          msgPostalCodeDoesntExist: 'Deze postcode bestaat niet',
          msgPostalCodeInvalidFormat: 'Deze postcode is niet correct. Voorbeeld: 1234 AB',
          APIError: 'De postcode kan niet worden gevalideerd. Probeer het later opnieuw',
          postalCodeisPOBox: 'Locaties zoeken op postbussen is niet mogelijk'
        }
      },
      expectedDateOfBirth: {
        errorMsgStartDateIsBeforeDateOfBirth: 'De startdatum van opvang is voor de (verwachte) geboortedatum van je kind'
      },
      startDateOfDayCare: {
        errorMsgStartDateIsBeforeDateOfBirth: 'De startdatum van opvang is voor de (verwachte) geboortedatum van je kind',
        errorMsgStartDateMustBeInFuture: 'De startdatum van opvang kan alleen in de toekomst zijn'
      },
      ageErrorMessage: 'Uw kind is ouder dan 13 jaar en komt niet in aanmerking voor kinderopvang.'
    }
  },
  availabilitySelector: {
    incorrectAgeTitle: 'Je kind is te oud/jong voor deze opvangvorm',
    incorrectAgeActiontext: 'Ontdek de opvang voor<br/>jouw kind',
    incorrectAgeDescriptionFooter: 'Of neem contact op om persoonlijk geholpen te worden.',
    incorrectSchoolTitle: 'Deze bso-locatie werkt (nog) niet samen met de school waar jouw kind naartoe gaat.',
    incorrectSchoolActiontext: 'Ontdek de opvang voor<br/>jouw kind',
    incorrectSchoolDescriptionFooter: 'Of neem contact op om persoonlijk geholpen te worden.',
    dateStepTitle: 'Meteen zien waar plek is?',
    schoolStepTitle: 'De Basisschool',
    schoolStepDescription: 'Onze bso\'s werken samen met verschillende basisscholen in de buurt. Daarom willen we graag weten naar welke school je kind gaat.',
    restartSelector: 'Gegevens wijzigen',
    showAvailability: 'Toon beschikbaarheid',
    checkAvailability: 'Check beschikbaarheid',
    book: 'Inschrijven',
    next: 'Verder'
  },
  serviceDetailPage: {
    title: '{serviceVarietyName} {street} | Partou',
    backButtonName: 'Terug',
    characteristics: 'Kenmerken',
    openingTimes: 'Openingstijden',
    locationinfo: 'Locatiegegevens',
    contactTitle: 'Hulp of advies nodig?',
    contactDescription: 'Neem contact met ons op via één van de contactbuttons.',
    chooseDays: 'Jouw opvangdagen',
    showOfferButton: 'Verder',
    locationsInNeighbourHood: 'Ontdek andere locaties',
    roomOnLocation: 'Is er plek op deze locatie?',
    roomOnLocationDescription: 'Bekijk hieronder de beschikbaarheid en kies je opvangdagen.<br/>Je ziet meteen op welke dagen er plek is!',
    moreInfo: 'Meer over deze locatie',
    bookExternal: 'Inschrijven',
    bookTour: 'Rondleiding boeken',
    multiStateDaySelector: {
      chooseDays: 'Kies je opvangdagen',
      title: 'Opvangdag beschikbaar?',
      beforeSchoolCare: 'Voorschoolse opvang',
      afterSchoolCare: 'Naschoolse opvang',
      dayCare: 'Kinderdagverblijf',
      loading: 'Controleren beschikbaarheid',
      legend: {
        description: 'Wil je per weekdag weten of er opvang mogelijk is op deze locatie? Hieronder leggen we het je uit.',
        seatAvailable: 'Er is plaats op deze dag',
        seatChosen: 'Deze dag heb je gekozen',
        queueAvailable: 'Wachtlijst beschikbaar',
        queueChosen: 'Je hebt voor een plaats op de wachtlijst gekozen',
        locationClosed: 'De locatie is op deze dag gesloten',
        placeAvailable: 'Er is een plek beschikbaar op deze dag',
        placeNotAvailable: 'Er is geen plek beschikbaar op deze dag',
        dayNotAvailable: 'Deze dag is niet beschikbaar'
      },
      popup: {
        nsoHeader: 'Naschoolse opvang',
        nsoMessage: 'Wat is er fijner voor je kind dan na een drukke schooldag relaxen met leeftijdsgenoten? Dat is precies wat je kind kan doen bij onze bso.',
        kdvHeader: 'Kinderdagverblijf',
        kdvMessage: 'Het leven is voor kinderen een grote ontdekkingstocht. Ons kinderdagverblijf is een veilige, vertrouwde speelplaats waar we samen oefenen voor de grote wereld.',
        vsoHeader: 'Voorschoolse opvang',
        vsoMessage: 'Zodra de locatie open is kan de opvang beginnen. En natuurlijk zorgen we ervoor dat je kind op tijd en veilig op school komt. Wel zo fijn!'
      }
    },
    serviceContent: {
      title: 'Praktische informatie'
    }
  },
  schoolSelectionPage: {
    title: 'De basisschool',
    description: 'Onze bso\'s werken samen met verschillende basisscholen in de buurt. Daarom willen we graag weten naar welke school je kind gaat.',
    locationSearchLabel: 'Plaatsnaam basisschool',
    locationSearchPlaceholder: 'bv Amsterdam',
    locationSearchErrorText: 'Er zijn geen plaatsen gevonden met deze naam',
    schoolSearchLabel: 'Naam basisschool',
    schoolSearchPlaceholder: 'bv Basisschool Sint Jan',
    schoolSearchErrorText: 'Er zijn geen scholen gevonden met deze naam',
    schoolGroupLabel: 'Basisschoolgroep (op de startdatum van de opvang)',
    schoolGroupPlaceholder: 'Basisschoolgroep',
    VSONSOCheckLabel: 'Welke opvang wil je?',
    VSOcheckboxText: 'Ik wil graag voorschoolse opvang',
    NSOcheckboxText: 'Ik wil graag naschoolse opvang',
    VSOHintText: 'Je kind kan ook \'s morgens voor school al bij ons terecht. Voor een relaxte start van de dag. En natuurlijk zorgen wij ervoor dat je kind veilig en op tijd op school komt. Wel zo fijn!',
    NSOHintText: 'We halen je kind op van school en zorgen voor leuke activiteiten op de locatie.',
    schoolInformationHint: 'Bij buitenschoolse opvang werken we samen met scholen. Daarom willen we graag weten waar je kind naar school gaat.<br/><br/>Kan je jouw plaatsnaam of school niet vinden? Dan werkt Partou nog niet samen met jouw school.',
    schoolNotAvailableHintTitle: 'Neem even contact met ons op!',
    schoolNotAvailableHintText: 'Helaas kunnen we op dit moment voor je gekozen school geen opvang aanbieden. Klik op één van de contactbuttons om samen de mogelijkheden te bespreken.',
    nextStepButton: 'Vind jouw Partou',
    locatlityAutocomplete: {
      nodata: 'Plaats niet gevonden'
    },
    schoolAutocomplete: {
      nodata: 'Geen scholen gevonden'
    },
    schoolGroupAutocomplete: {
      nodata: 'Geen school groepen gevonden'
    }
  },
  bookingStatusPage: {
    title: 'Gelukt!',
    stepTitle: 'Stap {0}',
    descriptionSingle: 'Dit is de laatste stap om je kinderopvang definitief te maken.',
    descriptionMultiple: 'Nog {0} stappen om je kinderopvang definitief te maken.',
    stepper: {
      email: 'E-mail',
      contract: 'Overeenkomst',
      SEPA: 'Machtiging'
    },
    emailVerifyContent: {
      title: 'E-mailbevestiging',
      description: 'Er is een e-mailbevestiging verstuurd naar: <span class="email-description">{0}</span><br/>Volg de link in deze mail om je emailadres te bevestigen.',
      noEmail: 'Geen mail ontvangen? Check ook je spambox.',
      retry: 'Opnieuw versturen'
    },
    signContractContent: {
      title: 'Digitaal ondertekenen',
      description: 'Je kunt de overeenkomst die we hebben opgesteld, digitaal ondertekenen. Geen papier, wel zo makkelijk!',
      startSign: 'Naar ondertekenen'
    },
    contractCannotYetBeSignedContent: {
      title: 'Contract wordt nog samengesteld',
      description: 'Je contract wordt nog samengesteld en kan nu nog niet worden ondertekend. Het samenstellen kan even duren. Probeer het later nog eens.'
    },
    signMandateContent: {
      title: 'Betalingsmachtiging',
      description: 'Kies hieronder je bank om een doorlopende machtiging voor automatische incasso aan Partou te bevestigen in je eigen bankomgeving.',
      startSign: 'Machtiging afgeven'
    },
    completed: {
      reservationBooking: {
        title: 'Helemaal compleet!',
        description: 'Je gegevens zijn compleet. Je kinderopvang is nu definitief geregeld.',
        nextSteps: {
          title: 'Wat gaan we nu doen?',
          step1: 'De locatie neemt contact met je op om een intake / wendag te plannen',
          step2: 'Voordat je opvang start, ontvang je de inloggegevens voor onze Partou ouder app',
          step3: 'Wil je iets wijzigen in je overeenkomst? Dat regel je eenvoudig zelf via partou.nl/service',
          description: 'Nogmaals welkom bij Partou en tot snel!'
        },
        backtoHomePage: 'Terug naar partou.nl'
      },
      waitingListBooking: {
        title: 'Bedankt!',
        description: 'Je inschrijving is bij ons binnen, we gaan voor je aan de slag. Zodra er plek is voor je kind, laten we je dit direct weten.',
        welcome: 'Tot snel!',
        backtoHomePage: 'Terug naar homepage'
      }
    },
    error: {
      cancelled: {
        title: 'Deze link is niet meer geldig',
        description: 'Deze link is verlopen of niet meer geldig. Het kan zijn dat je reservering is komen te vervallen of is ingetrokken. Wil je toch graag van onze opvang gebruikmaken? Schrijf jezelf dan opnieuw in of neem contact op met support@partou.nl.',
        button: 'Opnieuw inschrijven'
      },
      emailConfirmUrlNotFound: {
        title: 'Link in e-mailbevestiging verlopen',
        description: 'Deze link is verlopen of niet meer geldig omdat je een nieuwe link hebt aangevraagd.',
        button: 'Terug naar mijn boeking'
      },
      contractDeclined: {
        title: 'Je opvangplek is vervallen',
        description: 'We hadden je opvangplek gereserveerd, zodat je je inschrijving kon afronden. Helaas heb je je overeenkomst afgewezen. Wil je toch graag van onze opvang gebruikmaken?',
        button: 'Opnieuw inschrijven'
      }
    }
  },
  waitingListFlow: {
    general: {
      questionsOrHelp: 'Vragen of hulp nodig?'
    },
    cancelWaitingList: {
      title: 'Weet je zeker dat je je plek op de wachtlijst wilt annuleren?',
      buttonYes: 'Ja ik weet het zeker',
      buttonNo: 'Nee, ik wil mijn wachtlijstplek behouden'
    },
    bookWaitingList: {
      title: 'Voor welke opvangdagen wil je je inschrijven?',
      description: 'Selecteer hieronder de dag(en) waarvoor je je definitief wilt inschrijven.',
      confirmSelectedDays: 'Geselecteerde dagen reserveren',
      declineSelectedDays: 'Nee, nu (nog) even niet',
      bookLoadingText: 'Boeken kan even duren (tot wel 2 minuten)!',
      location: 'Locatie',
      startDate: 'Startdatum',
      popup: {
        title: 'Ben je zeker van de gekozen opvangdagen?',
        description: 'Je staat op het punt je in te schrijven voor de geselecteerde opvangdagen.<br/><br/>Let op!<br/><strong>Niet-geselecteerde</strong> dagen bieden we aan de volgende ouder op de wachtlijst aan.',
        confirm: 'Ja, dat klopt, ga verder',
        decline: 'Nee, ga terug'
      }
    },
    status: {
      titleCancelSuccess: 'Je bent van de wachtlijst gehaald',
      descriptionCancelSuccess: 'Je staat niet meer op de wachtlijst voor kinderopvang. Mocht je in de toekomst opvang zoeken? Je bent van harte welkom!',
      titleKeepWaitingList: 'Bedankt voor je reactie.',
      titleBookWaitingListSuccess: 'Yes! We voegen de opvangdag(en) toe aan je bestaande opvangpakket.',
      titleBookingRequiresPlanner: 'Bedankt voor je reactie. <br/> Een van onze planners neemt binnenkort contact met je op.'
    },
    error: {
      expired: {
        title: 'Oeps!<br/>Je reactietermijn is verlopen',
        body: 'Je staat op de wachtlijst en er is een opvangplek vrijgekomen, waarvoor je een aanbod hebt gekregen. Dit aanbod was {0} dagen geldig en deze termijn is helaas verlopen.'
      },
      unavailable: {
        title: 'Oeps!<br/>Je staat niet meer op de wachtlijst',
        body: 'Iedere 2 weken checken we bij je of je nog op de wachtlijst wilt blijven staan. We hebben geen reactie van je ontvangen. Hierdoor is je wachtlijstplek vervallen.'
      },
      stillSearching: 'Ben je nog op zoek naar kinderopvang?<br/>Klik dan hieronder',
      buttonSeeAvailableLocations: 'Beschikbare opvang bekijken'
    },
    dialog: {
      notTakingAllDays: {
        title: 'We zien dat je niet al de dagen gaat afnemen',
        titleNoDays: 'We zien dat je geen dagen gaat afnemen',
        possibleDays: 'Er is voor jou ook opvang mogelijk op',
        stayOnWaitingList: 'Wil je deze voorkeursdagen voor later wel behouden?',
        confirmStayOnWaitingList: 'Ja, ik wil dit graag voor later laten staan',
        reasonNotTakingDays: 'Wat is de reden dat je deze dagen niet afneemt?',
        changeWaitingList: 'Wil je iets wijzigen in je vooekeursdagen? Neem dan contact op via de onderstaande knoppen',
        maximumCharacters: 'Maximaal 125 tekens'
      }
    }
  },
  sharedPage: {
    expired: {
      title: 'Deze link is verlopen',
      body: 'Ben je nog op zoek naar Kinderopvang?',
      buttonRestart: 'Vind jouw Partou',
      contact: 'Vragen of hulp nodig?'
    }
  },
  notFound: {
    title1: 'Oh oh...',
    title2: 'Deze pagina is niet gevonden!',
    message: 'Hmmm... De pagina waarnaar je zoekt bestaat niet (meer).',
    buttonText: 'Terug naar Partou.nl',
    contact: 'Vragen of hulp nodig?'
  },
  preorder: {
    popup: {
      title: 'Graag tijdig je kinderopvang regelen?',
      description: 'Dat begrijpen we goed. Wij kunnen echter tot maximaal {months} maanden in de toekomst vooruit plannen.<br/><br/>Je kunt je wel alvast inschrijven op de wachtlijst. We nemen dan vanzelf contact met je op.',
      buttonText: 'Verder'
    }
  },
  feature: {
    signContract: {
      sign: {
        title: 'Overeenkomst uitbreiding',
        subtitle: 'Digitaal ondertekenen',
        content: 'Je kunt de overeenkomst die we hebben opgesteld, digitaal ondertekenen. Geen papier, wel zo makkelijk!',
        button: 'Naar ondertekenen'
      },
      signed: {
        title: 'Gelukt! We hebben de extra opvangdag(en) toegevoegd.'
      },
      declined: {
        title: 'Je hebt de overeenkomst afgewezen',
        subtitle: 'We hebben je een overeenkomst gestuurd voor de kinderopvang, maar je hebt ervoor gekozen deze niet te ondertekenen. We respecteren je beslissing en danken je voor je overweging!'
      },
      expired: {
        title: 'Je reservering is verlopen',
        subtitle: 'We hebben je gewenste kinderopvang {amountOfDays} dagen gereserveerd, zodat je je inschrijving in die periode kon afronden. Helaas is die periode verstreken.'
      },
      notFound: {
        title: 'Deze link is verlopen',
        subtitle: 'Ben je nog op zoek naar Kinderopvang?'
      },
      careInfo: {
        title: 'Wil je alsnog opvang aanvragen? Klik dan hieronder.',
        button: 'Vind jouw Partou'
      }
    },
    contactInfoCheck: {
      confirmButton: 'Bevestig mijn opvangplaats',
      newDays: {
        title: 'Uitbreiding',
        startsOn: 'Start op',
        kdv: 'Kinderdagopvang',
        nso: 'Naschoolse opvang',
        vso: 'Voorschoolse opvang'
      }
    }
  }
}

export default nl
